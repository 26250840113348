var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-carousel',{attrs:{"hide-delimiters":"","hide-delimiter-background":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.colors),function(color,index){return _c('v-carousel-item',{key:index},[_c('v-sheet',{attrs:{"color":color,"height":"100%","tile":""}},[_c('v-row',{staticClass:"fill-height text-center",attrs:{"align":"center","justify":"center"}},[_c('div',{},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"top":"","transition":"slide-y-transition","id":"button-1","role":"navigation","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":""}},'v-btn',attrs,false),on),[_vm._v("Button 1 name here")])]}}],null,true)},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"top":"","transition":"slide-y-transition","role":"navigation","id":"button-2","aria-label":"Click this button for a menu of Y","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":""}},'v-btn',attrs,false),on),[_vm._v("Button 2 name here")])]}}],null,true)},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"top":"","transition":"slide-y-transition","role":"navigation","id":"button-3","aria-label":"Click this button for a menu of Z","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":""}},'v-btn',attrs,false),on),[_vm._v("Button 3 name here")])]}}],null,true)},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)],1)],1)])],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }