var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mx-auto info-card px-2 py-2",class:{
      'mr-2':
        _vm.$vuetify.breakpoint.md ||
        _vm.$vuetify.breakpoint.lg ||
        _vm.$vuetify.breakpoint.xl,
      'ml-2':
        _vm.$vuetify.breakpoint.md ||
        _vm.$vuetify.breakpoint.lg ||
        _vm.$vuetify.breakpoint.xl,
    }},[_c('v-img',{attrs:{"src":"https://via.placeholder.com/200?text=Splash Photo","height":_vm.splashHeight}}),_c('v-card-title',[_vm._v(" Title here ")]),_c('v-card-subtitle',[_vm._v(" Teaser here ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","color":"grey lighten-1"}},[_vm._v("Read More »")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))]),_vm._v(" Description ")],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-divider'),_c('v-card-text',[_vm._v(" Lorem markdownum luctibus notavi belli aniis femur tulerat et lucem, miserabile. Caede praeacutae plagas pessima vivum ex et fortuna ne amor: caliginis. Effugit telis sollicita contenta quibus loquor capit copia carpens fortunam somnus. Stabat traderet coeunt ne cunctis faveas quas nefas, in terret expetit inminet Cyllenius ignara terra revocamina quem adplicat erat. Stabat inminet Cyllenius ignara terra revocamina quem adplicat erat. ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }