<template>
  <div class="px-12 py-6">
    <h2 class="mt-12 text-right">Photo #1</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash-10.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('blue')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Assessment and Planning</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed.
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>
    <h2 class="mt-12 text-right">Photo #2</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash-10.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('red')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Service Delivery</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed. concerns
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>

    <h2 class="mt-12 text-right">Photo #3</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash-11.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('blue')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Assessment and Planning</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed.
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>
    <h2 class="mt-12 text-right">Photo #4</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash-11.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('red')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Service Delivery</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed. concerns
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>

    <h2 class="mt-12 text-right">
      Photo #5 <span style="color: red">(In use)</span>
    </h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash-12.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('blue')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Assessment and Planning</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed.
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>
    <h2 class="mt-12 text-right">Photo #6</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash-12.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('red')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Service Delivery</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed. concerns
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>

    <h2 class="mt-12 text-right">Photo #7</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash-03.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('blue')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Assessment and Planning</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed.
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>
    <h2 class="mt-12 text-right">Photo #8</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash-03.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('red')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Service Delivery</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed. concerns
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>

    <h2 class="mt-12 text-right">Photo #9</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash-06.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('blue')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Assessment and Planning</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed.
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>
    <h2 class="mt-12 text-right">Photo #10</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash-06.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('red')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Service Delivery</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed. concerns
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>
    <h2 class="mt-12 text-right">Photo #11</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash-13.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('blue')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Assessment and Planning</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed. concerns
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>

    <h2 class="mt-12 text-right">
      Photo #12 <span style="color: red">(In use)</span>
    </h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash-13.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('red')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Service Delivery</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed. concerns
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>

    <!-- -->

    <h2 class="mt-12 text-right">Photo #13</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash14-min.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('blue')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Assessment and Planning</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed. concerns
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>

    <h2 class="mt-12 text-right">Photo #14</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash15-min.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('red')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Service Delivery</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed. concerns
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>

    <h2 class="mt-12 text-right">Photo #15</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash16-min.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('blue')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Assessment and Planning</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed. concerns
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>

    <h2 class="mt-12 text-right">Photo #16</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash17-min.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('red')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Service Delivery</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed. concerns
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>

    <h2 class="mt-12 text-right">Photo #17</h2>
    <v-card class="hoverCard">
      <v-img
        src="../../public/splash18-min.jpg"
        aspect-ratio="1"
        class="grey lighten-2 py-12"
        height="550"
      >
        <v-overlay absolute opacity=".4" :color="getBackground('blue')">
          <v-container
            class="fill-height"
            fluid
            full-width
            style="margin: 0 !important; padding: 0 !important"
          >
            <v-row justify="center" align="center">
              <div class="text-center px-5" style="min-width: 300px">
                <h1 class="nofo-title mt-3">Assessment and Planning</h1>
                <div class="nofo-tagline">
                  Lorem markdownum eiecit arreptamque oris populare, est illo
                  nos prospectat recepta in corporis discussa superest
                  praemonitus iubet est! Venerat ortae parum sed. concerns
                </div>
                <div class="mt-4" style="font-size: 13px; font-weight: bold">
                  Deadline: May 01, 2021
                </div>
                <v-btn class="mt-6" outlined color="white">Apply Now</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-overlay>
      </v-img>
    </v-card>

    <div style="height: 100px">&nbsp;</div>
  </div>
</template>

<script>
export default {
  methods: {
    getBackground() {
      let colorArr = {
        blue: ["#103f7c", "#103f7c"],
        red: ["#630308", "#630308"],
      };
      // last element in color array is darkest
      return colorArr[colorArr.length - 1];
    },
  },
};
</script>

<style lang="scss" scoped></style>
