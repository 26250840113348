var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-speed-dial',{attrs:{"bottom":true,"right":true,"direction":"top","open-on-hover":false,"absolute":"","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"blue darken-4","dark":"","fab":""},model:{value:(_vm.socialSharing),callback:function ($$v) {_vm.socialSharing=$$v},expression:"socialSharing"}},on),[(_vm.socialSharing)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v("fa fa-users")])],1)]}}])},[(!_vm.socialSharing)?_c('span',[_vm._v("Share or translate this page")]):_c('span',[_vm._v("Close sharing and translate")])])]},proxy:true}]),model:{value:(_vm.socialSharing),callback:function ($$v) {_vm.socialSharing=$$v},expression:"socialSharing"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"#3b5998"}},on),[_c('v-icon',[_vm._v("fab fa-facebook")])],1)]}}])},[_c('span',[_vm._v("Share this page on Facebook")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"#1DA1F2"}},on),[_c('v-icon',[_vm._v("fab fa-twitter")])],1)]}}])},[_c('span',[_vm._v("Share this page on Twitter")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"#4285F4"},on:{"click":function($event){return _vm.openTranslationModal()}}},on),[_c('v-icon',[_vm._v("fas fa-globe")])],1)]}}])},[_c('span',[_vm._v("Translate this page on Google")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }